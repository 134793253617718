import { Box, useMediaQuery, useTheme } from '@mui/material'
import MobileAppBar from '@Shared/MobileAppBar'
import MobileDrawer from '@Shared/MobileDrawer'
import React, { useState } from 'react'
import DesktopDrawer from './DesktopDrawer'
import { useUser } from '@Store/user'
const DKMDrawer: React.FC<DKMDrawerProps> = ({ isOpen, onToggleSidebar, name, avatarUrl, title, disableSideBar }) => {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const [mobileOpen, setMobileOpen] = useState(false)
	const { user } = useUser()

	const handleMobileToggle = () => {
		setMobileOpen(!mobileOpen)
	}

	return (
		<Box
			sx={{
				pointerEvents: user?.consent && !disableSideBar ? 'auto' : 'none',
			}}
		>
			{isMobile ? (
				<>
					<MobileAppBar onMenuClick={handleMobileToggle} />
					<MobileDrawer isOpen={mobileOpen} onClose={handleMobileToggle} name={name} avatarUrl={avatarUrl} title={title} />
				</>
			) : (
				<DesktopDrawer isOpen={isOpen} onToggleSidebar={onToggleSidebar} name={name} avatarUrl={avatarUrl} title={title} />
			)}
		</Box>
	)
}

export default DKMDrawer
