export enum FILE_UPLOAD_STATUS {
	PENDING = 'pending',
	SUCCESS = 'success',
	ERROR = 'error',
}
export const FILE_UPLOAD_MAX_SIZE = 30
export const FILE_UPLOAD_MAX_SIZE_UNIT = 'MB'
export enum FILE_TYPE {
	PDF = 'application/pdf',
	TXT = 'text/plain',
	DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};
export const FILE_UPLOAD_ALLOWED_TYPES = `${FILE_TYPE.PDF},${FILE_TYPE.TXT},${FILE_TYPE.DOCX}`
export const FILE_EXTENSION_TYPE_MAP = {
	pdf: FILE_TYPE.PDF,
	docx: FILE_TYPE.DOCX,
	txt: FILE_TYPE.TXT,
}

export const FILE_TYPE_INFO = {
	[FILE_TYPE.PDF]: {
		name: 'PDF',
		color: 'primary.main',
	},
	[FILE_TYPE.TXT]: {
		name: 'TXT',
		color: 'secondary.main',
	},
	[FILE_TYPE.DOCX]: {
		name: 'DOCX',
		color: 'warning.main',
	},
}

export const FILE_UPLOAD_BUTTON_TOOLTIP = `Drag-and-drop or Attach ${FILE_TYPE_INFO[FILE_TYPE.PDF].name}, ${FILE_TYPE_INFO[FILE_TYPE.TXT].name} or ${FILE_TYPE_INFO[FILE_TYPE.DOCX].name} files. Max ${FILE_UPLOAD_MAX_SIZE}${FILE_UPLOAD_MAX_SIZE_UNIT} each.`

export const FILE_UPLOAD_IN_PROGRESS_SEC = 15
export const FILE_UPLOAD_IN_PROGRESS_LABEL_INFO_SHOW_SEC = 30
export const FILE_UPLOAD_IN_PROGRESS_LABEL_CHANGE_SEC = 3