import { ERROR_MESSAGE_KNOWLEDGE_DISCOVERY, STRING_ERROR_CODE } from '@Constants/errors'
import { PRIVATE_ROUTES } from '@Constants/routes'
import { useKDShareConversation } from '@Data-Hooks/Mutations/useKDShareConversation'
import { useDialog } from '@Store/dialog'
import { useSnackbar } from '@Store/snackbar'
import { useEffect, useState } from 'react'

export const useKDShareConversationModalContentPage = (
	conversation: {
		id: string
		title: string
		is_public: boolean
	},
	refresh: () => void
) => {
	const [isNewShared, setIsNewShared] = useState<boolean>(conversation.is_public)
	const link = `${window.location.origin}${PRIVATE_ROUTES.shareConversationId.replace(':id?', conversation.id)}`
	const [isCopied, setIsCopied] = useState<boolean>(false)
	const { openToast } = useSnackbar()
	const { closeDialog } = useDialog()

	const { mutation: shareConversation } = useKDShareConversation({
		handleOnSuccess: (_, variables) => {
			refresh()
			const isShared = (variables as { id: string; isPublic: boolean }).isPublic;
			setIsNewShared(isShared)
			isShared && handleOnClickCopy()
		},
		handleOnError: (_, variables) => {
			openToast(
				'warning',
				ERROR_MESSAGE_KNOWLEDGE_DISCOVERY[
					variables.isPublic ? STRING_ERROR_CODE.KD_SHARE_CONVERSATION : STRING_ERROR_CODE.KD_DELETE_SHARED_CONVERSATION
				]
			)
		},
	})

	const handleOnClickCopy = async () => {
		await navigator.clipboard.writeText(link)
		setIsCopied(true)
	}

	useEffect(() => {
		if(isCopied) {
			setTimeout(() => setIsCopied(false), 2000)
		}
	}, [isCopied])

	useEffect(() => {
		!isNewShared && shareConversation.mutate({ id: conversation.id, isPublic: true })
	} , [])

	const handleOnClickCreateOrDelete = () => {
		shareConversation.mutate({ id: conversation.id, isPublic: !isNewShared })
	}

	const handleTeamsShare = async () => {
		await navigator.clipboard.writeText(link)
		closeDialog()
		window.location.href = 'msteams://'
	}

	const handleOutlookShare = () => {
		const subject = 'Share Conversation Link'
		const body = link

		// Encode the subject and body to handle spaces and special characters
		const encodedSubject = encodeURIComponent(subject)
		const encodedBody = encodeURIComponent(body)

		// Create the mailto link
		const mailtoLink = `mailto:?subject=${encodedSubject}&body=${encodedBody}`
		closeDialog()
		window.location.href = mailtoLink
	}

	return {
		isNewShared,
		link,
		handleOnClickCopy,
		isLinkCreating: shareConversation.isPending,
		handleOnClickCreateOrDelete,
		handleTeamsShare,
		handleOutlookShare,
		isCopied,
	}
}
