import { QueryClient } from '@tanstack/react-query'

const queryClient = new QueryClient()

queryClient.setDefaultOptions({
	queries: {
		refetchOnWindowFocus: false,
		retry: false,
		retryDelay: (attemptIndex) => Math.min(attemptIndex > 1 ? 2 ** attemptIndex * 1000 : 1000, 30 * 1000),
	},
	mutations: {
		retry: false,
	},
})

export default queryClient
