
const DKMWhatsNewIconSVG = ({ size, isWhatsNewMouseOver = false }: { size: number; isWhatsNewMouseOver?: boolean }) => {
	return (
		<svg width={size} height={size} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path style={{ animation: isWhatsNewMouseOver ? 'whatsNewIconTwo 1s ease-in-out' : '' }} d="M14.9861 0.938277C14.9361 1.00066 14.749 1.69935 14.5494 2.49786C13.7758 5.56712 12.7028 6.60269 9.53374 7.38872C7.41271 7.91274 7.41271 8.17475 9.52127 8.69877C12.7028 9.4848 13.7633 10.5204 14.5494 13.652C14.749 14.4505 14.9736 15.1617 15.0484 15.2365C15.3229 15.511 15.5725 15.0868 15.8594 13.8267C16.5581 10.6826 17.7559 9.47232 20.9249 8.69877C21.6611 8.51162 22.3223 8.31199 22.3972 8.23713C22.6966 7.93769 22.3598 7.72559 21.1745 7.46358C18.9412 6.95203 17.8432 6.35315 17.0197 5.2053C16.5332 4.51908 15.8968 2.83473 15.7222 1.69935C15.6723 1.38744 15.5725 1.06304 15.5101 0.975707C15.3604 0.801034 15.0859 0.77608 14.9861 0.938277Z" fill="url(#paint0_linear_28196_28156)" />
			<path style={{
				animation:isWhatsNewMouseOver ? 'whatsNewIconThree 1s ease-in-out' : '',
				animationDelay: isWhatsNewMouseOver ? '0.5s' : '',
			}} d="M20.075 2.87239C19.9877 3.23422 19.6384 3.57109 19.1767 3.77071L18.8398 3.90796L19.314 4.10758C19.6633 4.2573 19.8505 4.44445 20.0127 4.81875L20.2497 5.34277L20.4743 4.85618C20.6365 4.54427 20.8735 4.30721 21.1979 4.14501L21.6845 3.90796L21.1356 3.64595C20.7238 3.44632 20.5616 3.28412 20.4743 2.97221C20.337 2.49809 20.1873 2.46066 20.075 2.87239Z" fill="url(#paint1_linear_28196_28156)" />
			<path d="M3.17001 3.82075C2.12197 4.0079 1.31098 4.60678 0.774487 5.61739C0.5 6.12893 0.5 6.12893 0.5 13.4902C0.5 20.8389 0.5 20.8514 0.774487 21.3629C1.13631 22.0367 2.03463 22.8227 2.65846 23.0099C3.35716 23.222 16.9817 23.222 17.6804 23.0099C18.3042 22.8227 19.2025 22.0367 19.5644 21.3629C19.8264 20.8639 19.8388 20.7266 19.8763 16.2226C19.9137 12.0928 19.8887 11.5688 19.7141 11.3816C19.4645 11.1446 18.9904 11.1196 18.8033 11.3442C18.7159 11.4565 18.6536 13.0535 18.6286 16.0604L18.5912 20.6268L18.2418 21.076C17.5806 21.9494 17.9299 21.9119 10.1694 21.9119C2.40893 21.9119 2.75828 21.9494 2.09701 21.076L1.74767 20.6268V13.4777C1.74767 6.45333 1.74767 6.32856 2.00968 5.99169C2.14692 5.80454 2.42141 5.53005 2.60856 5.39281C2.93295 5.14327 3.15753 5.1308 7.2374 5.06841C10.2817 5.01851 11.5668 4.9686 11.6791 4.85631C11.8662 4.66916 11.8662 4.09523 11.6791 3.90808C11.5044 3.73341 4.09328 3.65855 3.17001 3.82075Z" fill="url(#paint2_linear_28196_28156)" />
			<path style={{ animation: isWhatsNewMouseOver ? 'whatsNewIconOne 1s ease-in-out' : '' }} d="M9.15888 11.1264C9.12329 11.2421 9.05211 11.4823 9.01652 11.6513C8.82077 12.5856 8.34029 13.0394 7.23698 13.3419C6.94336 13.4309 6.70312 13.5466 6.70312 13.6088C6.70312 13.6711 7.03234 13.8313 7.45943 13.9647C8.40258 14.2673 8.7051 14.5876 8.99872 15.5574C9.19447 16.1892 9.37242 16.4383 9.4525 16.1714C9.4703 16.1091 9.55927 15.8066 9.63935 15.504C9.90628 14.5787 10.4579 14.0804 11.4634 13.8669C12.0862 13.7334 12.0328 13.5377 11.3299 13.3508C11.0363 13.2707 10.6626 13.1195 10.5024 13.0305C10.102 12.7902 9.71943 12.1852 9.58597 11.598C9.4525 11.0107 9.28345 10.8239 9.15888 11.1264Z" fill="url(#paint3_linear_28196_28156)" />
			<defs>
				<linearGradient id="paint0_linear_28196_28156" x1="7.94141" y1="8.073" x2="22.1616" y2="10.2675" gradientUnits="userSpaceOnUse">
					<stop stopColor="#AE5DED" />
					<stop offset="1" stopColor="#46C8D9" />
				</linearGradient>
				<linearGradient id="paint1_linear_28196_28156" x1="18.8398" y1="3.96544" x2="21.6147" y2="4.40549" gradientUnits="userSpaceOnUse">
					<stop stopColor="#AE5DED" />
					<stop offset="1" stopColor="#46C8D9" />
				</linearGradient>
				<linearGradient id="paint2_linear_28196_28156" x1="0.5" y1="13.45" x2="19.4466" y2="16.3525" gradientUnits="userSpaceOnUse">
					<stop stopColor="#AE5DED" />
					<stop offset="1" stopColor="#46C8D9" />
				</linearGradient>
				<linearGradient id="paint3_linear_28196_28156" x1="6.70313" y1="13.6304" x2="11.781" y2="14.3953" gradientUnits="userSpaceOnUse">
					<stop stopColor="#AE5DED" />
					<stop offset="1" stopColor="#46C8D9" />
				</linearGradient>
			</defs>
		</svg>
	)
}

export default DKMWhatsNewIconSVG