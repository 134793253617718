import { SIDE_BAR_MENU_ITEM_BORDER_RADIUS, WHATS_NEW_SITE } from "@Constants/commons"
import { IconButton, ListItemButton, ListItemIcon, ListItemText, Stack, Tooltip, Typography, useTheme } from "@mui/material"
import DKMWhatsNewIconSVG from "@Shared/DKMWhatsNewIconSVG"
import { FC, useEffect, useState } from "react"
import { footerIconButtonStyles } from "./styles"

const text = "What's new?"

const AnimatedTextLine: FC = () => {
	const [displayedText, setDisplayedText] = useState<string>('')
	const typeNextCharacter = (currentLine: string, charIndex: number) => {
		setDisplayedText(currentLine.substring(0, charIndex + 1))
	}

	useEffect(() => {
		let charIndex = 0
		const typingInterval = setInterval(() => {
			if (charIndex < text.length) {
				typeNextCharacter(text, charIndex)
				charIndex = charIndex + 1
			} else {
				clearInterval(typingInterval)
			}
		}, 100)
		return () => clearInterval(typingInterval)
	}, [text])

	return (
		<Stack spacing={0.5} direction={'row'}>
			<Typography
				variant={'subtitle2'}
				className={'gradientText'}
				fontWeight={500}
			>
				{displayedText}
			</Typography>
		</Stack>
	)
}

const WhatsNewItem: FC<{ showOnlyIcon?: boolean }> = ({ showOnlyIcon = false }) => {
	const theme = useTheme()
	const [isWhatsNewMouseOver, setIsWhatsNewMouseOver] = useState<boolean>(false)
	const handleWhatsNewMouseOver = () => {
		setIsWhatsNewMouseOver(true)
	}

	const handleWhatsNewMouseLeave = () => {
		setIsWhatsNewMouseOver(false)
	}

	const handleWhatsNewOnClick = () => window.open(WHATS_NEW_SITE, '_blank')

	if (showOnlyIcon) {
		return (
			<Tooltip title={"What's new?"} placement="right">
				<IconButton
					sx={{...footerIconButtonStyles(theme), width: 40, height: 40 }}
					onClick={() => window.open(WHATS_NEW_SITE, '_blank')}
					onMouseOver={handleWhatsNewMouseOver}
					onMouseLeave={handleWhatsNewMouseLeave}
				>
					<DKMWhatsNewIconSVG size={22} isWhatsNewMouseOver={isWhatsNewMouseOver} />
				</IconButton>
			</Tooltip>
		)
	}
	
	return (
		<ListItemButton
			onClick={handleWhatsNewOnClick}
			onMouseOver={handleWhatsNewMouseOver}
			onMouseLeave={handleWhatsNewMouseLeave}
			sx={{
				pl: 0.5,
				borderRadius: SIDE_BAR_MENU_ITEM_BORDER_RADIUS,
				':hover': {
					cursor: 'pointer',
					background: theme.palette.grey[100],
				},
			}}
		>
			<ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
				<DKMWhatsNewIconSVG size={16} isWhatsNewMouseOver={isWhatsNewMouseOver} />
			</ListItemIcon>
			<ListItemText
				sx={isWhatsNewMouseOver ? { minHeight: 22 } : {}}
				primary={isWhatsNewMouseOver ? (
					<AnimatedTextLine />
				) : (
					<Typography
						variant="subtitle2"
						fontWeight={500}
					>
						{text}
					</Typography>
				)}
			/>
		</ListItemButton>
	)
}

export default WhatsNewItem