import { kdCloneSharedConversationForMe, kdShareConversation } from '@Requests/knowledge.api'
import { DefaultError, useMutation } from '@tanstack/react-query'

export const useKDShareConversation = ({ handleOnSuccess, handleOnError }: IMutationParameters) => {
	const mutation = useMutation({
		mutationFn: async ({ id, isPublic }: { id: string; isPublic: boolean }) => await kdShareConversation(id, isPublic),
		onSuccess: (response, variables) => {
			handleOnSuccess?.(response.data, variables)
		},
		onError: (err: DefaultError, variables) => {
			handleOnError?.(err, variables)
		},
	})
	return {
		mutation,
	}
}

export const useKDCloneSharedConversationForMe = ({ handleOnSuccess, handleOnError }: IMutationParameters) => {
	const mutation = useMutation({
		mutationFn: async ({ id }: { id: string }) => await kdCloneSharedConversationForMe(id),
		onSuccess: (response) => {
			handleOnSuccess?.(response.data)
		},
		onError: (err: DefaultError) => {
			handleOnError?.(err)
		},
	})
	return {
		mutation,
	}
}
