import { DRAWER_WIDTH } from "@Constants/commons"
import { getInitials } from "@Libs/utilities"
import { Avatar, Box, Tooltip, Typography, useTheme } from "@mui/material"
import { FC, useEffect, useRef, useState } from "react"

interface IDKMUserInfoComponentProps {
	name: string
	title: string
	avatarUrl: string
}

const DKMUserInfo: FC<IDKMUserInfoComponentProps> = ({ name, title, avatarUrl }) => {
	const theme = useTheme()

	const nameRef = useRef<HTMLDivElement>(null)
	const titleRef = useRef<HTMLDivElement>(null)

	const [isNameOverflowing, setIsNameOverflowing] = useState(false)
	const [isTitleOverflowing, setIsTitleOverflowing] = useState(false)

	useEffect(() => {
		const checkOverflow = (element: HTMLElement | null) => {
			if (element) {
				return element.scrollWidth > element.clientWidth
			}
			return false
		}

		const checkTitleOverflow = (element: HTMLElement | null) => {
			if (element) {
				return element.scrollHeight > element.clientHeight
			}
			return false
		}

		setIsNameOverflowing(checkOverflow(nameRef.current))
		setIsTitleOverflowing(checkTitleOverflow(titleRef.current))
	}, [name, title])

	return (
		<Box flexDirection="row" display="flex" alignItems="center" width={DRAWER_WIDTH - 48}>
			<Avatar
				alt="avatar"
				src={avatarUrl}
				data-cy="avatar"
				sx={{
					backgroundColor: 'primary.main',
				}}
			>
				<Typography variant="h6">{getInitials(name)}</Typography>
			</Avatar>
			<Box ml={2} width={140}>
				{/* Name with single-line ellipsis */}
				<Tooltip title={isNameOverflowing ? name : ''} placement="top" disableHoverListener={!isNameOverflowing}>
					<Typography
						variant="body2"
						fontWeight={700}
						ref={nameRef}
						sx={{
							color: theme.palette.primary['90'],
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							maxWidth: '100%',
						}}
						data-cy="user-name"
					>
						{name}
					</Typography>
				</Tooltip>
				<Tooltip title={isTitleOverflowing ? title : ''} placement="top" disableHoverListener={!isTitleOverflowing}>
					<Typography
						variant="subtitle2"
						ref={titleRef}
						sx={{
							whiteSpace: 'normal',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							display: '-webkit-box',
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: 2,
							maxWidth: '100%',
						}}
						data-cy="user-title"
					>
						{title}
					</Typography>
				</Tooltip>
			</Box>
		</Box>
	)
}

export default DKMUserInfo