import CustomDialog from '@Shared/CustomDialog'
import DKMErrorBoundary from '@Shared/DKMErrorBoundary'
import RightDrawerOverlay from '@Shared/RightDrawerOverlay'
import SnackbarComponent from '@Shared/Snackbar'
import { useDialog } from '@Store/dialog'
import { useRightDrawerOverlay } from '@Store/rightDrawerOverlay'
import { useSnackbar } from '@Store/snackbar'
import React from 'react'
import AppRoutes from './Routes'
import './index.css'
import DKMMaintenance from '@Shared/DKMMaintenance'
import { useApp } from '@Hooks/useApp'
import FullScreenLoader from '@Shared/Loader/FullScreenLoader'

const App: React.FC = () => {
	const { isMaintenanceMode, isLoading } = useApp()
	const { isOpen: isAlertOpen, message, severity, closeToast, key: alertKey } = useSnackbar((state) => state)
	const { open: dialogOpen, closeDialog, title, description, body, actions, maxWidth } = useDialog((state) => state)
	const {
		open: rightDrawerOpen,
		closeDrawer,
		title: rightDrawerTile,
		children: rightDrawerChildren,
		size,
	} = useRightDrawerOverlay((state) => state)

	if (isLoading) {
		return <FullScreenLoader />
	}

	if (isMaintenanceMode) {
		return <DKMMaintenance />
	}

	return (
		<DKMErrorBoundary>
			<SnackbarComponent
				key={alertKey}
				isOpen={isAlertOpen}
				message={message}
				severity={severity}
				closeAlert={closeToast}
			/>
			<CustomDialog
				open={dialogOpen}
				closeDialog={closeDialog}
				title={title}
				description={description}
				body={body}
				actions={actions}
				maxWidth={maxWidth}
			/>
			<RightDrawerOverlay closeDrawer={closeDrawer} open={rightDrawerOpen} title={rightDrawerTile} size={size}>
				{rightDrawerChildren}
			</RightDrawerOverlay>
			<AppRoutes />
		</DKMErrorBoundary>
	)
}

export default App
