import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"

const DKMMaintenance = () => {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Box mt={isMobile ? 0 : -10.5}>
				<img alt="ADB_logo" src="/images/maintenance.png" width={isMobile ? 170 : 228} />
			</Box>
			<Stack flex={1} px={{ xs: 2, sm: 5, md: 15, lg: 25 }} alignItems={'center'} justifyContent={'center'}>
				<Typography textAlign={'center'} variant={'h2Bold'}>
					{'We’re turning up a few things.'}
				</Typography>
				<Typography textAlign={'center'} variant={'subtitle1'}>
					{'We apologize for the inconvenience but Navigator is currently undergoing planned maintenance.'}
				</Typography>
			</Stack>
		</Box>
	)
}

export default DKMMaintenance