import { useKDDownloadConversationPage } from '@Hooks/Common/KDDownloadConversation/useKDDownloadConversationPage'
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material'
import GradientCircularProgress from '@Shared/GradientCircularProgress'
import { IconDownload } from '@tabler/icons-react'
import { FC, memo } from 'react'

const KDDownloadConversation: FC<IKDDownloadConversationComponentProps> = ({ id, handleMenuClose }) => {
	const { downloadConversation, isDownloadOptionActive } = useKDDownloadConversationPage(id, handleMenuClose)
	return (
		<MenuItem
			disabled={isDownloadOptionActive}
			onClick={(e) => {
				e.stopPropagation()
				downloadConversation()
			}}
		>
			<ListItemIcon>{isDownloadOptionActive ? <GradientCircularProgress /> : <IconDownload size={20} />}</ListItemIcon>
			<ListItemText primary={<Typography variant="body2">Download Conversation</Typography>} />
		</MenuItem>
	)
}

export default memo(KDDownloadConversation)
