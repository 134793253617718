import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { THEME_MODE_LIGHT } from '../Constants/commons'

export const useUser = create<IUserStore>()(
	persist(
		(set) => ({
			user: null,
			externalKDQuery: '',
			updateExternalKDQuery: (externalKDQuery: string) => set({ externalKDQuery }),
			updateUser: (user) => set({ user }),
			// themeMode: window.matchMedia('(prefers-color-scheme: dark)').matches ? THEME_MODE_DARK : THEME_MODE_LIGHT,
			themeMode: THEME_MODE_LIGHT,
			mobileAppInfo: true,
			updateThemeMode: (themeMode) => set({ themeMode }),
			toggleMobileAppInfo: () => set((state) => ({ mobileAppInfo: !state.mobileAppInfo })),
			sharedConversationId: '',
			updateSharedConversationId: (sharedConversationId: string) => set({ sharedConversationId }),
		}),
		{ name: 'userStore', storage: createJSONStorage(() => sessionStorage) }
	)
)
