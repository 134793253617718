import { logAction } from "@Libs/utilities";

export const getMaintenanceMode = async () => {
	try {
		const response = await fetch(process.env.REACT_APP_MAINTENANCE_API ?? '', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
		const data = await response.json()
		return data
	} catch (error) {
		logAction({
			actionName: 'MAINTENANCE_MODE_FETCH_REQUEST_ERROR',
			actionType: 'error',
			actionData: {
				prompt: (error as ISessionStreamAPIError)?.message ?? 'Maintenance Mode Fetch Request Error',
			},
		})
	}
}