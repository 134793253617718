import {
	ERROR_CODE,
	ERROR_MESSAGE,
	ERROR_MESSAGE_KNOWLEDGE_DISCOVERY,
	ERROR_MESSAGE_TASK_ASSISTANCE,
	ERROR_NAME,
	ERROR_TITLE,
	STRING_ERROR_CODE,
} from '../Constants/errors'
import { formatDistance } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'
import { Buffer } from 'buffer'
import React from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { MOBILE_APP_BAR_HEIGHT } from '@Constants/commons'

export const timeSince = (date: string) => {
	return formatDistance(new Date(date), new Date(), { addSuffix: true })
}

export const getInitials = (name: string) => {
	if (!name) return ''
	const names = name.split(' ')
	const initials = names.length > 1 ? `${names[0][0]}${names[1][0]}` : names[0][0]
	return initials.toUpperCase()
}

export const processStreamData = async (
	streamData: ReadableStream<Uint8Array>,
	processCallback: (chunk: IStreamAIResponse, correctQuestionSpell: boolean) => void,
	correctQuestionSpell = false
) => {
	if (streamData.locked) {
		return
	}
	const reader = streamData.getReader()
	const decoder = new TextDecoder()
	let buffer = ''
	const reading = true
	while (reading) {
		try {
			const { done, value } = await reader.read()
			if (done) {
				reader.cancel()
				break
			}

			const chunk = decoder.decode(value, { stream: true })
			buffer += chunk

			let newlineIndex
			while ((newlineIndex = buffer.indexOf('\n')) !== -1) {
				const line = buffer.slice(0, newlineIndex).trim()
				buffer = buffer.slice(newlineIndex + 1)
				if (line) {
					processCallback(JSON.parse(line), correctQuestionSpell)
				}
			}
		} catch (err) {
			if ((err as Error).name !== 'AbortError') {
				reader.cancel()
			}
			break
		}
	}
}

/**
 * @summary { Helper function to get the knowledge discovery error messages }
 * @param { number } status
 * @param { string } stringCode
 * @param { string } name -- Optional
 * @returns { IErrorAIObject }
 */
export const getKnowledgeDiscoveryErrorMessage = (status: number, stringCode: string, name = ''): IErrorAIObject => {
	// Not acceptable error message
	if (status === ERROR_CODE.UNPROCESSABLE_ENTITY && stringCode.includes(STRING_ERROR_CODE.CONTENT_SAFETY)) {
		return {
			title: ERROR_TITLE[STRING_ERROR_CODE.CONTENT_SAFETY],
			description: ERROR_MESSAGE[STRING_ERROR_CODE.CONTENT_SAFETY],
		}
	}

	// Abort Signal error message
	if (status === ERROR_CODE.ABORT_SIGNAL && name === ERROR_NAME[ERROR_CODE.ABORT_SIGNAL]) {
		return {
			title: ERROR_TITLE[ERROR_CODE.ABORT_SIGNAL],
			description: ERROR_MESSAGE_KNOWLEDGE_DISCOVERY[ERROR_CODE.ABORT_SIGNAL],
		}
	}

	// General error message
	return {
		title: ERROR_TITLE[ERROR_CODE.INTERNAL_SERVER_ERROR],
		description: ERROR_MESSAGE_KNOWLEDGE_DISCOVERY[ERROR_CODE.INTERNAL_SERVER_ERROR],
	}
}

/**
 * @summary { Helper function to get the super charge task error messages }
 * @param { number } status
 * @param { string } stringCode
 * @param { string } name -- Optional
 * @returns { IErrorAIObject }
 */
export const getTaskAssistanceErrorMessage = (status: number, stringCode: string, name = ''): IErrorAIObject => {
	// Not acceptable error message
	if (status === ERROR_CODE.UNPROCESSABLE_ENTITY && stringCode.includes(STRING_ERROR_CODE.CONTENT_SAFETY)) {
		return {
			title: ERROR_TITLE[STRING_ERROR_CODE.CONTENT_SAFETY],
			description: ERROR_MESSAGE[STRING_ERROR_CODE.CONTENT_SAFETY],
		}
	}

	// Abort Signal error message
	if (status === ERROR_CODE.ABORT_SIGNAL && name === ERROR_NAME[ERROR_CODE.ABORT_SIGNAL]) {
		return {
			title: ERROR_TITLE[ERROR_CODE.ABORT_SIGNAL],
			description: ERROR_MESSAGE_TASK_ASSISTANCE[ERROR_CODE.ABORT_SIGNAL],
		}
	}

	// General error message
	return {
		title: ERROR_TITLE[ERROR_CODE.INTERNAL_SERVER_ERROR],
		description: ERROR_MESSAGE_TASK_ASSISTANCE[ERROR_CODE.INTERNAL_SERVER_ERROR],
	}
}

export const buttonHasText = (children: React.ReactNode) => {
	// Check if the children are text or contain text
	return typeof children === 'string' || React.Children.toArray(children).some((child) => typeof child === 'string')
}

export const getCspNonce = (): string | undefined => {
	const base64String = Buffer.from(uuidv4()).toString('base64')
	return base64String
}

export const isSpaceFound = (text: string): boolean => {
	return /\s/.test(text)
}

export const setUserToData = ({ id, name, email }: IUserDetails) => {
	const isProd = process.env.NODE_ENV === 'production'
	if (isProd) {
		datadogRum.setUser({ id, name, email })
		return
	}
	return undefined
}

export const logAction = ({ actionName, actionType, actionData }: ILogActionParams) => {
	const isProd = process.env.NODE_ENV === 'production'
	if (isProd) {
		actionType === 'error'
			? datadogRum.addError(actionName, { ...actionData, timestamp: new Date().toISOString() })
			: datadogRum.addAction(actionName, { ...actionData, timestamp: new Date().toISOString() })
		return
	}
	console[actionType](actionName, actionData)
}

export const getPrivateContainerHeight = (isMobile: boolean, mobileAppInfo: boolean) => {
	const isMobileActualDevice = /Mobi|Android/i.test(navigator.userAgent)
	// Desktop view
	if (!isMobile) {
		return '100vh'
	}
	// Actual mobile view with mobile app info bar
	if (mobileAppInfo && isMobileActualDevice) {
		return `calc(100vh - ${(MOBILE_APP_BAR_HEIGHT + 20) * 2.5}px)`
	}
	// Actual mobile view with out mobile app info bar
	if (!mobileAppInfo && isMobileActualDevice) {
		return `calc(100vh - ${MOBILE_APP_BAR_HEIGHT * 2.5}px)`
	}
	// Desktop mobile view
	return `calc(100vh - ${MOBILE_APP_BAR_HEIGHT}px)`
}
