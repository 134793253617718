import DKMLogo from '@Shared/DKMLogo'
import DKMUserInfo from '@Shared/DKMUserInfo'
import { useUser } from '@Store/user'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import { IconLayoutSidebarLeftCollapseFilled, IconLifebuoy } from '@tabler/icons-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { SIDE_BAR_MENU_ITEM_BORDER_RADIUS } from './../../Constants/commons'
import { PRIVATE_ROUTES } from './../../Constants/routes'
import { getCspNonce, getInitials } from './../../Libs/utilities'
import { NavigationItems } from './components/NavigationItems'
import { RecentConversations } from './components/RecentConversations'
import WhatsNewItem from './components/WhatsNewItem'
import { drawerStyles, footerIconButtonStyles } from './components/styles'

interface DesktopDrawerProps {
	isOpen: boolean
	onToggleSidebar: () => void
	name: string
	avatarUrl: string
	title: string
}

const FooterIconButton: React.FC<{
	title: string
	onClick: () => void
	icon: React.ReactNode
	dataCy?: string
}> = ({ title, onClick, icon, dataCy }) => {
	const theme = useTheme()
	return (
		<Tooltip title={title} placement="right">
			<IconButton sx={footerIconButtonStyles(theme)} onClick={onClick} data-cy={dataCy}>
				{icon}
			</IconButton>
		</Tooltip>
	)
}

const FooterListItemButton: React.FC<{
	onClick: () => void
	icon: React.ReactNode
	text: string
}> = ({ onClick, icon, text }) => {
	const theme = useTheme()
	return (
		<ListItemButton
			onClick={onClick}
			sx={{
				pl: 0.5,
				borderRadius: SIDE_BAR_MENU_ITEM_BORDER_RADIUS,
				':hover': {
					cursor: 'pointer',
					background: theme.palette.grey[100],
				},
			}}
		>
			<ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>{icon}</ListItemIcon>
			<ListItemText
				primary={
					<Typography variant="subtitle2" fontWeight={500}>
						{text}
					</Typography>
				}
			/>
		</ListItemButton>
	)
}

const ScrollableContent = styled(Box)(({ theme }) => ({
	flexGrow: 1,
	overflowY: 'auto',
	overflowX: 'hidden',
	'&::-webkit-scrollbar': {
		width: 4,
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: theme.palette.grey[300],
		borderRadius: 2,
	},
}))
const StyledUserInfoBox = styled(Box)(({ theme }) => ({
	position: 'sticky',
	bottom: 0,
	padding: theme.spacing(1),
	width: '100%',
}))

export const DesktopDrawer: React.FC<DesktopDrawerProps> = ({ isOpen, onToggleSidebar, name, avatarUrl, title }) => {
	const theme = useTheme()
	const navigate = useNavigate()
	const { user } = useUser()

	return (
		<Drawer
			variant="permanent"
			open={isOpen}
			data-cy="desktop-drawer"
			sx={{
				flexShrink: 0,
				whiteSpace: 'nowrap',
				boxSizing: 'border-box',
				...(isOpen ? drawerStyles.openedMixin(theme) : drawerStyles.closedMixin(theme)),
				'& .MuiDrawer-paper': {
					...(isOpen ? drawerStyles.openedMixin(theme) : drawerStyles.closedMixin(theme)),
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
				},
			}}
		>
			<Stack alignItems={'center'} sx={{ pb: 1, pt: isOpen ? 3 : 1 }}>
				<DKMLogo showText={isOpen} />
			</Stack>

			<ScrollableContent nonce={getCspNonce()}>
				<NavigationItems isOpen={isOpen} />
				{user?.consent && <RecentConversations isOpen={isOpen} />}
			</ScrollableContent>

			<StyledUserInfoBox data-cy="drawer-footer" nonce={getCspNonce()}>
				{!isOpen ? (
					<Box>
						<Box display="flex" justifyContent="center" mb={2}>
							<WhatsNewItem showOnlyIcon />
						</Box>
						<Box display="flex" justifyContent="center" mb={2}>
							<FooterIconButton title="Need help?" onClick={() => navigate(PRIVATE_ROUTES.faqs)} icon={<IconLifebuoy />} />
						</Box>
						<Box display="flex" justifyContent="center" mb={3}>
							<FooterIconButton
								title="Expand"
								onClick={onToggleSidebar}
								icon={<IconLayoutSidebarLeftCollapseFilled style={{ transform: 'rotate(180deg)' }} />}
								dataCy="expand-button"
							/>
						</Box>
						<Stack justifyContent="center" alignItems="center">
							<Avatar
								alt="avatar"
								src={avatarUrl}
								data-cy="avatar"
								sx={{
									backgroundColor: 'primary.main',
								}}
							>
								<Typography variant="h6">{getInitials(name)}</Typography>
							</Avatar>
						</Stack>
					</Box>
				) : (
					<Stack spacing={1}>
						<Box px={1}>
							<WhatsNewItem />
							<FooterListItemButton
								onClick={() => navigate(PRIVATE_ROUTES.faqs)}
								icon={<IconLifebuoy size={18} />}
								text="Need help?"
							/>
						</Box>
						<Stack direction="row" justifyContent="space-between" alignItems="center" px={2}>
							<DKMUserInfo name={name} title={title} avatarUrl={avatarUrl} />
							<FooterIconButton
								title="Collapse"
								onClick={onToggleSidebar}
								icon={<IconLayoutSidebarLeftCollapseFilled />}
								dataCy="toggle-sidebar-button"
							/>
						</Stack>
					</Stack>
				)}
			</StyledUserInfoBox>
		</Drawer>
	)
}

export default DesktopDrawer
