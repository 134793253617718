import { Button, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material'
import { useDialog } from '@Store/dialog'
import { IconShare } from '@tabler/icons-react'
import { FC, memo, useCallback } from 'react'
import KDShareConversationModalContent from './KDShareConversationModalContent'

const KDShareConversation: FC<IKDShareConversationComponentProps> = ({ handleMenuClose, refresh, conversation, disabled = false, context = 'workspace' }) => {
	const { openDialog } = useDialog()
	const handleShareIconClickPage = useCallback(() => {
		handleMenuClose()
		openDialog(
			'Share link to ADB Members',
			'Your name, conversation title, files, generated sources, and all messages will be shared. Any message you add after will stay private.',
			{
				body: <KDShareConversationModalContent conversation={conversation} refresh={refresh} />,
			},
			'sm'
		)
	}, [conversation])

	if (context === 'conversation') {
		return (
			<Button
				disabled={disabled}
				color={'default'}
				variant={'outlined'}
				onClick={(e) => {
					e.stopPropagation()
					handleShareIconClickPage()
				}}
				startIcon={<IconShare />}
			>
				{'Share'}
			</Button>
		)
	} 

	return (
		<MenuItem
			onClick={(e) => {
				e.stopPropagation()
				handleShareIconClickPage()
			}}
		>
			<ListItemIcon>{<IconShare size={20} />}</ListItemIcon>
			<ListItemText primary={<Typography variant="body2">Share Conversation</Typography>} />
		</MenuItem>
	)
}

export default memo(KDShareConversation)
