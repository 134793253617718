import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export const useConversationSession = create<IConversationSessionStore>()(
	persist(
		(set) => ({
			disablePromptInputBar: false,
			conversationMetaData: { id: '', title: '', isPublic: false },
			updateDisablePromptInputBar: (disablePromptInputBar: boolean) => set({ disablePromptInputBar }),
			setConversationMetaData: (data: IConversationMetaData) => set({ conversationMetaData: data }),
		}),
		{ name: 'conversationSessionStore', storage: createJSONStorage(() => sessionStorage) }
	)
)