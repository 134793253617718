import queryClient from '@Config/queryClientProvider'
import { QUERY_KEY } from '@Constants/commons'
import { ERROR_MESSAGE_KNOWLEDGE_DISCOVERY, STRING_ERROR_CODE } from '@Constants/errors'
import { useKDDownloadConversation } from '@Data-Hooks/Queries/useConversations'
import { useSnackbar } from '@Store/snackbar'
import { useCallback, useEffect, useState } from 'react'

export const useKDDownloadConversationPage = (conversationId: string, handleMenuClose: () => void) => {
	const { openToast } = useSnackbar()
	const [isDownloadOptionActive, setIsDownloadOptionActive] = useState<boolean>(false)

	const { data: file, isError: isFileDownloadError } = useKDDownloadConversation(conversationId, isDownloadOptionActive)

	useEffect(() => {
		if (file && isDownloadOptionActive) {
			let url = ''
			// Create a link element
			const link = document.createElement('a')
			try {
				const { data, headers } = file
				// Create a blob from the response data
				const blob = new Blob([data], { type: headers['content-type'] })

				url = window.URL.createObjectURL(blob)

				// Assign url
				link.href = url

				// Get the filename from the content-disposition header
				const contentDisposition = headers['content-disposition']
				const filename = contentDisposition
					? contentDisposition.split('filename=')[1].replace(/['"]/g, '')
					: 'downloadedFile'

				// Set the download attribute and trigger the download
				link.setAttribute('download', filename)
				document.body.appendChild(link)
				link.click()
				clearStates(link, url)
			} catch (error) {
				openToast('warning', ERROR_MESSAGE_KNOWLEDGE_DISCOVERY[STRING_ERROR_CODE.KD_DOWNLOAD_CONVERSATION])
				clearStates(link, url, false)
			}
		}
	}, [file, isDownloadOptionActive])

	useEffect(() => {
		if (isFileDownloadError && isDownloadOptionActive) {
			openToast('warning', ERROR_MESSAGE_KNOWLEDGE_DISCOVERY[STRING_ERROR_CODE.KD_DOWNLOAD_CONVERSATION])
			setIsDownloadOptionActive(false)
		}
	}, [isFileDownloadError, isDownloadOptionActive])

	const clearStates = useCallback((link: HTMLAnchorElement, url: string, closeMenu = true) => {
		// Remove the download query key as it serves the instant request. So it will be active, every time user's tries to download. 
		queryClient.removeQueries({
			queryKey: [QUERY_KEY.KD_DOWNLOAD_CONVERSATION],
		});
		// Clean up and remove the link
		document.body.removeChild(link)
		// Revoke the blob URL after a short delay
		setTimeout(() => {
			url && window.URL.revokeObjectURL(url)
		}, 100)
		setIsDownloadOptionActive(false)
		closeMenu && handleMenuClose()
	}, [])

	const downloadConversation = useCallback(() => {
		setIsDownloadOptionActive(true)
	}, [])

	return {
		downloadConversation,
		isDownloadOptionActive,
	}
}
