export const PRIVATE_ROUTES = {
	task: '/task',
	knowledge: '/knowledge',
	conversationId: '/knowledge/conversation/:id?',
	workspace: '/workspace',
	logout: '/logout',
	taskInput: '/task/input',
	taskSessionId: '/task/session/:id?',
	favorites: '/favorites',
	faqs: '/faqs',
	consent: '/consent',
	shareConversationId: '/knowledge/share/conversation/:id?',
}

export const PUBLIC_ROUTES = {
	root: '/',
}

export const ROUTE_TITLE = {
	[PRIVATE_ROUTES.task]: 'Task Assistant',
	[PRIVATE_ROUTES.knowledge]: 'Knowledge Discovery',
	[PRIVATE_ROUTES.workspace]: 'My Workspace',
	[PRIVATE_ROUTES.favorites]: 'My Favorites',
	[PRIVATE_ROUTES.faqs]: 'FAQs',
	[PRIVATE_ROUTES.consent]: 'Consent',
}
