import { PRIVATE_ROUTES } from '@Constants/routes'
import { useGetMaintenanceMode } from '@Data-Hooks/Queries/useApp'
import { useUser } from '@Store/user'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const useApp = () => {
	const { updateSharedConversationId } = useUser()
	const [isMaintenanceMode, setIsMaintenanceMode] = useState(false)
	const { data, isLoading } = useGetMaintenanceMode()
	const location = useLocation()

	useEffect(() => {
		if (data) {
			setIsMaintenanceMode(data.maintenanceMode === 'true')
		}
	}, [data])

	useEffect(() => {
		if (location.pathname.includes(PRIVATE_ROUTES.shareConversationId.replace(':id?', ''))) {
			const urlArray = location.pathname.split('/')
			updateSharedConversationId(urlArray[urlArray.length - 1])
		}
	}, [])

	return {
		isMaintenanceMode,
		isLoading,
	}
}
