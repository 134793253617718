import { SHOW_KD_CONVERSATION_SHARE_VIA_MS_APPS } from '@Constants/commons'
import { useKDShareConversationModalContentPage } from '@Hooks/Common/KDShareConversation/useKDShareConversationModalContentPage'
import {
	Box,
	Button,
	IconButton,
	Stack,
	TextField,
	Tooltip,
	Typography
} from '@mui/material'
import DKMMSOutlookIconSVG from '@Shared/DKMMSOutlookIconSVG'
import DKMMSTeamsIconSVG from '@Shared/DKMMSTeamsIconSVG'
import DKMSwitch from '@Shared/DKMSwitch'
import { IconCheck, IconCopy } from '@tabler/icons-react'
import { FC } from 'react'

const KDShareConversationModalContent: FC<{
	conversation: {
		id: string
		title: string
		is_public: boolean
	}
	refresh: () => void
}> = ({ conversation, refresh }) => {
	const {
		isNewShared,
		link,
		handleOnClickCopy,
		isLinkCreating,
		handleOnClickCreateOrDelete,
		handleTeamsShare,
		handleOutlookShare,
		isCopied,
	} = useKDShareConversationModalContentPage(conversation, refresh)

	return (
		<Box>
			<Stack mt={1} spacing={1} alignItems={'center'} direction={'row'}>
				<TextField
					sx={{
						flex: 1,
						p: 0,
						'.MuiOutlinedInput-input': {
							p: 1,
						},
					}}
					value={link}
					label=""
					variant="outlined"
					disabled
				/>
				<Box>
					<Button
						color={'primary'}
						disabled={!isNewShared || isLinkCreating} 
						variant={'contained'}
						onClick={handleOnClickCopy}
						startIcon={isCopied ? <IconCheck size={20} /> : <IconCopy size={20} />}
					>
						{isCopied ? 'Copied' : 'Copy Link'}
					</Button>
				</Box>
			</Stack>
			<Stack alignItems={'center'} direction={'row'} mt={3}>
				<Stack spacing={0.5} flex={1}>
					<Typography variant={'h5Bold'}>{'Active link'}</Typography>
					{!isNewShared && (
						<Typography color={'grey.500'} variant={'subtitle3'}>
							{"The link will activate automatically once you select 'Share'."}
						</Typography>
					)}
				</Stack>
				<Box>
					<DKMSwitch onChange={handleOnClickCreateOrDelete} checked={isNewShared} disabled={isLinkCreating} />
				</Box>
			</Stack>
			{SHOW_KD_CONVERSATION_SHARE_VIA_MS_APPS && (
				<Stack spacing={4} alignItems={'center'} justifyContent={'center'} direction={'row'} mt={2}>
					<Stack spacing={0.5} alignItems={'center'}>
						<Tooltip title={'Share via Teams'} placement={'top'}>
							<IconButton
								onClick={handleTeamsShare}
								disabled={!isNewShared}
								sx={{
									width: 56,
									height: 56, 
									opacity: !isNewShared ? 0.3 : 1,
									bgcolor: 'common.grayscaleBase',
									':hover': {
										cursor: 'pointer',
										bgcolor: 'grey.100',
									},
								}}
							>
								<DKMMSTeamsIconSVG size={32} />
							</IconButton>
						</Tooltip>
						<Typography variant={'body1'}>{'Teams'}</Typography>
					</Stack>
					<Stack spacing={0.5} alignItems={'center'}>
						<Tooltip title={'Share via Outlook'} placement={'top'}>
							<IconButton
								onClick={handleOutlookShare}
								disabled={!isNewShared}
								sx={{
									width: 56,
									height: 56, 
									opacity: !isNewShared ? 0.3 : 1,
									bgcolor: 'common.grayscaleBase',
									':hover': {
										cursor: 'pointer',
										bgcolor: 'grey.100',
									},
								}}
							>
								<DKMMSOutlookIconSVG size={32} />
							</IconButton>
						</Tooltip>
						<Typography variant={'body1'}>{'Outlook'}</Typography>
					</Stack>
				</Stack>
			)}
		</Box>
	)
}

export default KDShareConversationModalContent
